import { Box, Button, Typography } from '@mui/material';
import type { NextPage } from 'next';
import NextLink from 'next/link';
import MainLayout from '../src/components/layouts/MainLayout';
import Seo from '../src/components/Seo';

const Page: NextPage = () => {
  return (
    <MainLayout remoteUser>
      <Seo title="お探しのページは見つかりません" />
      <Box
        sx={{
          pb: 8,
          pt: 8,
        }}
      >
        <Typography component="h1" variant="h3" align="center" color="text.primary" gutterBottom>
          お探しのページは見つかりません
        </Typography>

        <Typography variant="body1" align="center" color="text.secondary" paragraph>
          アクセスするURLが間違っているか、リンクをクリックした場合は、リンクが古い可能性があります。
        </Typography>
        <Box sx={{ mt: 8, textAlign: 'center' }}>
          <NextLink href="/" passHref>
            <Button variant="outlined" color="secondary" size="large" sx={{ margin: '0 auto' }}>
              トップページへ
            </Button>
          </NextLink>
        </Box>
      </Box>
    </MainLayout>
  );
};

export default Page;
